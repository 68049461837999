/*@import "../login/style.scss";*/

.app-register {
    background-color: #ffffff;
    .app-form__container {
        padding-top: 0;
    }
}
.react-autosuggest__suggestions-container {
    position: absolute;
    background: rgba(236, 235, 235, 1);
    padding: 12px 22px;
    box-shadow: rgb(204, 204, 204) 1px 3px 8px;
    color: #021843;
    z-index: 1;
    display: none;

    &--open {
        display: block;
    }

    ul {
        list-style: none;
        text-align: left;
        margin: 0;
        padding: 0;
        line-height: 21px;

        li {
            cursor: pointer;
        }
    }
}