.app-promo__container {
    position: fixed;
    bottom: 63px;
    right: 3%;
    max-width: 350px;
    max-height: 80%;
    z-index: 1000;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.498039) 0px 5px 15px 0px;

    .app-promo__img {
        margin-top: 24px;
        img {
            width: 100%;
        }
    }

    .app-promo__body {
        padding: 15px;
        background-color: #ffffff;
        border: 1px solid #B07F2E;

        .app-promo__content {
            padding: 0 10px;

            p {
                letter-spacing: .1px;
                font-size: 13px;
                color: #010F2A;
                word-wrap: break-word;
                margin-bottom: 13px;
                margin-top: 10px;
                text-align: left;
                line-height: 1.8em;
            }
        }
    }

    .app-promo__block-actions {
        margin-top: 17px;
        padding-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        justify-content: space-evenly;
    }

    .app-promo__close {
      position: absolute;
      top: 2px;
      right: 15px;
      font-size: 2em;
      font-weight: 100;
      color: #B07F2E;
      cursor: pointer;
    }
}