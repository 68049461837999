.app-modal__container {
    .app-form__wrapper {
        background-color: #ffffff;
        width: 640px;
        margin: 5% auto;
        position: relative;

        .app-form {
            width: 80%;
        }

        .heading {
            padding: 20px 0;
            .app-heading-line-text {
                margin: 0 1em;
            }
        }

        .app-form__container {
            padding-top: 0;
            margin-top: 20px;
            padding-bottom: 10px;

            .app-form__group_actions {
                margin: 20px 0;
            }
        }
        .app-content-page {
            padding: 0 20px;
        }


        .close {
            position: absolute;
            top: 5px;
            right: 10px;
            font-size: 2em;
            font-weight: 100;
            color: #B07F2E;
            cursor: pointer;
        }

        @media only screen and (max-width: 640px) {
            width: 100%;

            .app-form {
                width: auto;
                padding: 0 25px;
            }
        }
    }
}