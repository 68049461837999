.hotel-location {
  background-color: #ffffff !important;

  &-map-container {
    margin: 0;
    padding: 15px 20px 0;
    overflow: hidden;
    iframe {
      width: 100%;
      border: none
    }
  }

  .container {
    background-color: white;
    padding: 0 40px;

    .editor-content {
      padding: 20px 20px 0;
      text-align: left;
      letter-spacing: .1px;
      font-size: 13px;
      color: #010F2A;

      h2 {
        font-size: 21px;
        margin: 19px 0;
        letter-spacing: 3px;
        text-align: left;
        font-weight: 600;
      }

      p {
        letter-spacing: .1px;
        font-size: 13px;
        line-height: 1.8;
        color: #010F2A;
        word-wrap: break-word;
        margin-bottom: 13px;
        margin-top: 10px;
      }
    }
  }

  .about-section {
    background: #fff;
    padding: 0 40px;
    margin-bottom: 30px;

    .container {
      margin-right: auto;
      margin-left: auto;
      padding-left: 15px;
      padding-right: 15px;
      text-align: left;
    }

    .contact-detail {
      .address {
        text-align: left;
      }

      table {
        font-size: 13px;

        td {
          padding-right: 6px;
          vertical-align: top;

          &:first-child {
            white-space: nowrap;
            padding-right: 11px;
          }

          a {
            text-decoration: none;
            color: inherit;
          }
        }
      }
    }

    .contact-block {
      padding: 0 20px;
      text-align: left;

      p {
        line-height: 1.8;
        margin: 0 0 10px;
        font-size: 13px;
      }
    }

    .title {
      font-size: 21px;
      margin: 19px 0;
      letter-spacing: 3px;
      text-align: left;
      font-weight: 600;
    }

    @media only screen and (max-width: 640px) {
      padding: 0 10px;
    }

  }


  table {
    text-align: left;
  }
}