.home-block-wrapper {

  margin: 15px 20px;

  background-color: white;

  .text-heading {
    font-size: 18px;
    color: #010F2A;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 24px;
    text-align: left;
  }

  .slider-block, .special-cards {
    p {
      letter-spacing: .1px;
      font-size: 13px;
      color: #010F2A;
      word-wrap: break-word;
      margin-bottom: 13px;
      margin-top: 10px;
      text-align: left;
      line-height: 1.8;
      padding-right: 25px;
    }
  }

  .home-content-block {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.9);

    .home-map-container {
      flex: 1 0 50%;
      min-height: 400px;
      max-width: 50%;
      iframe {
        border: none;
      }
    }
  }

  .home-block-text {
    margin: 0 100px 13px 70px;
    flex: 1 1 50%;
    p {
      letter-spacing: .1px;
      font-size: 13px;
      color: #010F2A;
      word-wrap: break-word;
      margin-bottom: 13px;
      margin-top: 10px;
      text-align: left;
      line-height: 1.8em;

      a {
        color: #010F2A;
      }
    }
  }

  @media only screen and (max-width: 1125px) {
    .home-content-block {
      align-items: stretch;
      justify-content: center;
      flex-direction: column;
      .home-map-container {
        height: 400px;
        max-width: 100%;
      }
    }

  }

  @media only screen and (max-width: 640px) {
    .home-block-text {
      margin: 0 10px;
      text-align: center;
      p {
        text-align: center;
      }
    }

  }
}

.special-cards-wrapper {
  background-color: #f3f3f3;
  overflow: hidden;

  .heading {
    padding-bottom: 0;
    margin-bottom: 30px;
  }

  .special-cards {
    .card {
      width: 85%;
      background-color: white;
      margin: 15px auto 0;
      margin-bottom: 30px;
      text-align: left;
      display: flex;
      align-items: flex-start;

      .card-img {
        line-height: 0;
      }

      .details-text, span {
        width: 100%;
        letter-spacing: .1px;
        font-size: 13px;
        color: #010F2A;
        word-wrap: break-word;
        margin-bottom: 13px;
        margin-top: 10px;
        line-height: 1.8;
      }

      .title {
        font-size: 22px;
        font-style: italic;
        color: #010F2A;
        margin: 10px 0 5px;
        width: 95%;

        a {
          text-decoration: none;
          color: #010F2A;
        }
      }

      .info-wrapper {
        width: 55%;
        flex-grow: 1;

        .card-content {
          padding: 5px 20px;

          .card-text {
            display: flex;
            flex-direction: row;
            align-items: baseline;
          }

          .text-valid {
            font-size: 11px;
            font-weight: 300;
          }
        }

        span {
          white-space: nowrap;

          .info {
            white-space: initial;
          }
        }
      }

      .value {
        width: 25%;

        div {
          font-size: 14px;
          font-style: italic;
          padding: 6px;
          width: 50%;
          vertical-align: middle;
          display: inline-block;
          line-height: 1;
        }

        span {
          font-style: italic;
          font-family: "KingsCaslon", Arial, sans-serif;
          display: inline-block;
          width: 50%;
          vertical-align: middle;
          line-height: 1;
          text-align: center;
          color: #010F2A;
          font-size: 25px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .special-cards {
      .card {
        display: block;
        padding: 10px 10px 0;

        .value {
          span {
            font-size: 18px;
          }
        }

        .card-img {
          max-height: 168px;
          overflow: hidden;
          display: flex;
          align-items: center;
        }

        img {
          width: 100%;
        }

        .info-wrapper {
          width: 90%;
        }
      }
    }
  }
  @media only screen and (max-width: 1070px) {
    .special-cards {
      .card {
        .value {
          span {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1040px) {
  .special-cards {
    .card {
      .value {
        width: 65% !important;
        text-align: center;
      }
    }
  }
}

.slider-block, .special-cards {
  p {
    letter-spacing: .1px;
    font-size: 13px;
    color: #010F2A;
    word-wrap: break-word;
    margin-bottom: 13px;
    margin-top: 10px;
    text-align: left;
    line-height: 1.8;
    padding-right: 25px;
  }
}
.app-content {
  .slider-block {
    margin-top: -10px;
  }
}

.app-content-slider__block-actions {
  margin-top: 17px;
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}

.app-content-slider__block-actions_right {
  text-align: right;
  flex-direction: row-reverse;
}

.app-content-slider__block-actions_distribute {
  text-align: center;
  justify-content: space-evenly;
}

.photo-galery, .ideas {
  .heading {
    margin-top: 0;
  }
}

.photo-galery {
  background-color: #f3f3f3;

  .slick-slider {
    overflow: hidden;
    max-height: 540px;
    display: flex;
    align-items: center;
    line-height: 0;

    .app-slider__left,
    .app-slider__right {
      opacity: 1;
    }
  }
}

.ideas {
  .slick-slider {
    position: initial;
    margin: 0 auto;
    width: 85%;
  }
}

@media only screen and (max-width: 460px) {
  .photo-galery {
    .slick-slider {
      height: fit-content;
    }
  }

  .app-content-slider__block-actions {
    flex-direction: column;
    text-align: center;
  }
}

.ideas {
  background-color: #f3f3f3;

  .special-cards {
    .card {
      background-color: white;
      margin: 0 auto;
      text-align: left;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .card-img {
        width: 40%;
        min-width: 405px;
        flex-grow: 1;
        overflow: hidden;
        display: flex;
        align-items: center;
        max-height: 300px;

        img {
          width: 100%;
        }
      }

      p {
        letter-spacing: .1px;
        font-size: 13px;
        color: #010F2A;
        word-wrap: break-word;
        margin-bottom: 13px;
        margin-top: 10px;
        text-align: left;
        line-height: 1.8;
        padding-right: 25px;
      }

      .title {
        font-size: 22px;
        font-style: italic;
        color: #010F2A;
        margin-top: 10px;
        width: 95%;
      }

      .info-wrapper {
        width: 55%;
        flex-grow: 1;

        .card-content {
          padding: 5px 20px;
        }
      }

      .value {
        width: 15%;
        margin-top: 2%;
      }
    }
  }

  @media only screen and (max-width: 460px) {
    .special-cards {
      .card {
        display: block;

        .card-img {
          max-height: 150px;
        }

        img, .info-wrapper {
          width: 100%;
        }
      }
    }
  }
}

.heading {
  .app-heading-title-text {
    font-weight: 300;
    letter-spacing: -0.05em;
    line-height: 1.4em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 0.7em;
    font-size: 1.2em;
    font-style: italic;
  }

  .app-heading-line-text {
    margin: 0 2em;
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .app-heading-line-left,
  .app-heading-line-right {
    width: 10%;
    border-bottom: 1px solid #B07F2E;
    margin: 0;
  }
}

.names-slider {
  margin: 0 -20px 20px;
  display: flex;
  justify-content: center;

  .slick-list {
    min-width: 540px;
    .slick-track {
      display: flex;
      align-items: center;
    }
  }
  .names-slide {
    cursor: pointer;
    outline: none !important;
    span {
      width: 80%;
      display: block;
      margin: 0 auto;
      text-align: center;
      line-height: 24px;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 4px;
      text-transform: uppercase;
    }
  }
  .slick-slide {
    min-width: 180px;
  }
  .slick-active.slick-center {
    .names-slide {
      span {
        border-bottom: 1px solid #B07F2E;
      }
    }
  }
}