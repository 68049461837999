.rooms {

  .sub-child-title {
    font-size: 10px;
    font-family: "Helvetica", Arial, sans-serif;
    letter-spacing: 5px;
    color: #010F2A;
    text-transform: uppercase;
    margin-top: 18px;
  }

  .categories-wrapper {
    padding: 15px 20px;
    background-color: white;

    .app-slider__right,
    .app-slider__left {
      top: 150px;
    }

    .special-cards {
      .card {
        background-color: white;
        margin: 0 auto;
        text-align: left;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        .card-img {
          width: 40%;
          flex-grow: 1;
          min-width: 300px;
          overflow: hidden;
          display: flex;
          align-items: center;

          @media only screen and (max-width: 768px) {
            max-height: 200px;
          }

          img {
            width: 100%;
          }
        }

        p {
          letter-spacing: .1px;
          font-size: 13px;
          color: #010F2A;
          word-wrap: break-word;
          margin-bottom: 13px;
          margin-top: 10px;
          text-align: left;
          line-height: 1.8;
          padding-right: 25px;
        }

        .title {
          font-size: 22px;
          font-style: italic;
          color: #010F2A;
          margin-top: 10px;
          width: 95%;
        }

        .info-wrapper {
          width: 55%;
          padding-left: 20px;
          flex-grow: 1;
        }

        .value {
          width: 15%;
          margin-top: 2%;
        }

      }
    }
  }

  .card-details {
    color: rgb(1, 15, 42);
    padding: 0 15%;
    text-align: left;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0.1px;
    line-height: 23px;
    word-wrap: break-word;

    display: flex;
    flex-wrap: wrap;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        flex: 1 0 42%;
        padding: 0 12px;
        min-width: 150px;
        font-size: 12px;
        line-height: 16px;
      }
    }

    div {
      flex: 1 1 33%;
      min-width: 250px;
    }

    h3 {
      text-align: center;
      width: 100%;
    }
  }

  .gallery {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 101;
    background-color: rgba(1, 15, 42, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .app-content-slider {
      img {
        width: 60% !important;
        padding: 80px;
      }
    }

    .app-content-slider__left,
    .app-content-slider__right {
      color: #B07F2E;
    }

    .close {
      position: absolute;
      top: 5px;
      right: 10px;
      font-size: 3em;
      font-weight: 100;
      color: #B07F2E;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 768px) {
    .card-details {
      padding: 0;
    }
  }

  .app-content-page__value {
    span {
      font-size: 1.4em;
      color: #B07F2E;
    }
  }

  .app-content-page {
    padding-bottom: 0;
    .heading {
      padding: 0;
    }
  }
}



