@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.app-login {
  background-color: #ffffff;
}

.app-form__container {
  background-color: #ffffff;
  padding: 30px 0;
  font-size: 14px;
  line-height: 20px;
}

.app-form {
  width: 50%;
  margin: auto;

  @media only screen and (max-width: 1040px) {
    width: 60%;
  }

  @media only screen and (max-width: 768px) {
    width: 80%;
  }

  @media only screen and (max-width: 640px) {
    width: 95%;
  }
}

.app-form__group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
}

.app-form__group_actions {
  justify-content: center;

  button {
    margin: 0;
  }
}

.app-form__label {
  flex: 0 0 30%;
  text-align: left;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.1px;
  line-height: 23px;
  padding-right: 5px;
}

.app-form__input {
  flex: 0 0 70%;

  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
    }
  }

  input[type=text], input[type=email], input[type=tel],
  input[type=password], input[type=date], select {
    border: 1px solid #ccc;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    display: block;
    box-sizing: border-box;
    height: 31px !important;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.4em;
  }

  input[type=checkbox] {
    margin: 6px 12px;
  }

  textarea {
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    padding: 6px 12px;
    border: 1px solid #ccc;
    box-shadow: none;
    border-radius: 0;
  }

  .app-form__error {
    display: block;
    color: #ff6666;
    text-align: left;
  }
}

