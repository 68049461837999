.app-content-page {
    color: rgb(1, 15, 42);
    margin: 0;
    padding: 15px 20px;
    background: #ffffff;

    &__wrapper {
      .special-cards-wrapper {
          padding-bottom: 30px;
      }
    }

    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap-reverse;
        overflow: hidden;
    }

    &__image {
        flex: 1 0 50%;
        min-width: 512px;

       img {
           width: 100%;
       }
    }

    &__text {
        flex: 1 0 50%;
        padding: 0;
        overflow-x: hidden;
    }

    &__description {
        padding: 0 70px;
        text-align: justify;
        font-size: 13px;
        font-weight: 300;
        letter-spacing: 0.1px;
        line-height: 23px;
        word-wrap: break-word;

        p {
            margin-top: 0;
        }
    }

    &__block-actions {
        margin-top: 17px;
        padding-bottom: 10px;
        display: flex;
        flex-wrap: wrap;

        &_right {
          text-align: right;
          flex-direction: row-reverse;
        }
    }
}

@media only screen and (max-width: 640px) {
    .app-content-page {
        &__description {
            padding: 0 5px;
        }
        &__text {
            padding-top: 10px;
        }
        &__image {
            height: 150px;
            overflow: hidden;
            img {
                margin-top: -50px;
            }
        }
    }
}