.map__container {
    height: 100%;
}
.map__container_static {
    max-height: 450px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    img {
        width: 200%;
    }
}