#root{
    overflow-x: hidden;
}

.app {
    text-align: center;
}

.app-link {
    color: #61dafb;
}

.cursor {
    cursor: pointer;
}

.app-bread-crumbs {
    flex: 0 0 auto;
    background: rgba(236,235,235,0.6);
    color: #000;
    border-bottom: 1px solid #ecedeb;
}

.app-bread-crumbs ul {
    margin: 0 0 0 100px;
    padding: 0.8em 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-items: flex-start;
}

.app-bread-crumbs ul li {
    margin-left: 0.5em;
    font-size: 0.6em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #B07F2E;
    opacity: 0.8;

    &:last-child {
      color: inherit;
    }
}

.app-bread-crumbs ul li a {
    text-decoration: none;
    color: inherit;
}

.app-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-items: flex-start;
    background: #f3f3f3;
}

.app-content-block-wrapper {
    flex: 0 0 100%;
    margin: 0 20px;
}

.app-content-block {
    margin: 0 1.2em;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    background: rgba(255,255,255,0.9);
}

.app-content-block {
    text-align: left;
}

.app-content-block > h3 {
    padding-left: 1.2em;
    width: 200px;
}

.app-content-block > p {
    padding-left: 1.2em;
    width: 400px;
}

.app-content-block-text {
    width: 300px;
    padding: 0 1.2em;
}

.app-block-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    flex: 1 1 auto;
}

.app-block-column {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 1 auto;
}


.route-page {
    min-height: 300px;
    background-color: #f3f3f3;
}

.heading {
    padding: 30px 0;
}

.heading .sub-title .line {
    position: absolute;
    left: 0;
    top: 49%;
    width: 100%;
    height: 1px;
    background: #B5A191;
}

.heading .sub-title {
    font-style: italic;
    margin-top: 8px;
    position: relative;
    display: inline-block;
    min-width: 413px;
    max-width: 100%;
    line-height: 1.44;
    color: #010F2A;
    background: inherit;
}

.heading .sub-child-title {
    font-size: 10px;
    font-family: "Helvetica", Arial, sans-serif;
    letter-spacing: 5px;
    color: #010F2A;
    text-transform: uppercase;
    margin-top: 18px;

    a {
        color: inherit;
        text-decoration: none;
    }
}

.heading .sub-title span {
    white-space: nowrap;
    text-transform: none;
    font-style: italic;
    letter-spacing: normal;
    position: relative;
    z-index: 9;
    background: inherit;
    padding: 0 30px;
    font-family: "KingsCaslon",Arial,sans-serif;
    display: block;
}

.heading h2 {
    font-size: 20px;
    letter-spacing: 5px;
    line-height: 1.44;
    color: #010F2A;
    outline-color: rgb(1, 15, 42);
    margin: 0;
    font-weight: 300;
    text-transform: uppercase;

    a {
        text-decoration: none;
        color: inherit;
    }
}

.read-more {
    font-size: 11px;
    text-transform: uppercase;
    text-decoration: none;
    outline: none !important;
    border-radius: 0;
    background: transparent;
    border: 2px solid #B5A191;
    color: #021843;
    transition: all 0.3s;
    font-weight: bold !important;
    padding: 6px 30px;
    line-height: 1.4em;
    cursor: pointer;
    display: inline-block;
    margin: 0 0 10px;

    &:hover {
      background-color: #B5A191;
      color: #fff;
      transition: all 0.3s;
    }
}

.action {
    font-size: 11px;
    text-transform: uppercase;
    text-decoration: none;
    outline: none !important;
    border-radius: 0;
    border: 2px solid #B5A191;
    background-color: #B5A191;
    color: #fff;
    transition: all 0.3s;
    font-weight: bold !important;
    padding: 6px 30px;
    line-height: 1.4em;
    cursor: pointer;
    display: inline-block;
    margin: 0 0 10px;

    &:hover {
      background-color: transparent;
      color: #021843;
      transition: all 0.3s;
    }
}

@media only screen and (max-width: 640px) {
    .app-bread-crumbs ul {
        margin-left: 20px;
    }
}