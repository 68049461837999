.special-offers {
  padding-bottom: 30px;

  .card-content .card-text > div:first-child {
    flex-grow: 1;
  }

  .card-content .value {
    display: flex;
    flex-direction: column;
    align-content: end;
    align-items: flex-end;
  }
}