.app-header {
  background: rgba(1, 15, 42, 0.75) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  min-height: 80vh;

  .app-header-top {
    width: 100%;
    flex: 0 0 auto;
    background: rgba(1, 15, 42, 0.75);
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    min-height: 100px;
  }

  .app-header-logo {
    line-height: 0;
  }

  .app-logo {
    height: 100px;
    margin: 0 15px 0 65px;
    pointer-events: none;
    flex: 0 0 auto;
  }

  .app-header-title {
    flex: 8 1 auto;
    margin: 19px 0 19px 10%;
    text-shadow: 0 -1px 2px #030000;
  }

  .app-header-title h1 {
    text-transform: uppercase;
    letter-spacing: 0.3em;
    font-weight: 300;
    font-size: 1.2em;
    margin: 0 0 0.8em;
  }

  .app-header-title-text {
    font-weight: 300;
    letter-spacing: -0.05em;
    line-height: 1.4em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .app-header-line-text {
    white-space: nowrap;
  }

  .app-header-line-left,
  .app-header-line-right {
    width: 15%;
    border-bottom: 1px solid #B07F2E;
    margin: 0 0.6em;
  }

  .app-header__toggle {
    flex: 0 0 36px;
    height: 36px;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    display: none;
    margin: 0;

    .app-header__toggle-stick {
      background-color: #B07F2E;
      height: 5px;
      border-radius: 5px;
      width: 100%;
    }
  }

  .app-header__toggle_open {
    .app-header__toggle-stick {
      background-color: #B07F2E;
      opacity: 0.5;
    }
  }

  .app-header-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    margin: 12px 50px 12px 0;
    text-align: right;
    flex: 1 0 10%;
  }

  .app-header-lang {
    flex: 1 1 auto;
    text-transform: uppercase;
    font-size: 0.7em;
    font-weight: 500;
    letter-spacing: 0.2em;
    white-space: nowrap;
    position: relative;
    padding-top: 11px;
  }

  .app-header__lang {
    cursor: pointer;

    img {
      height: 13px;
      margin-bottom: -3px;
    }

    &_open {
      opacity: 0.65;
    }
  }

  .app-header-lang a {
    text-decoration: none;
    color: inherit;
  }

  .app-header-lang select {
    display: inline-block;
    font-size: 1em;
  }

  .app-header__lang_open {
    opacity: 0.85;
  }

  .app-header-login {
    flex: 1 1 auto;
    padding-top: 11px;
    position: relative;

    a {
      display: block;
      text-decoration: none;
      color: inherit;
      text-transform: uppercase;
      font-size: 0.7em;
      line-height: 2em;
      font-weight: 500;
      letter-spacing: 0.2em;
      white-space: nowrap;
    }
  }

  .app-header__livechat {

  }

  .login {
    display: block;
    text-decoration: none;
    color: inherit;
    text-transform: uppercase;
    font-size: 0.7em;
    font-weight: 500;
    letter-spacing: 0.2em;
    white-space: nowrap;
  }

  .app-header-menu {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: stretch;
    padding: 1.5em 0;
  }

  .app-header-menu-items {
    list-style: none;
    text-align: left;
    margin: 0;
    padding: 0;
  }

  .app-header-menu-items li {
    font-size: 12px;
    line-height: 27px;
    padding: 0 1em 0 50px;
    margin-bottom: 1px;
    text-transform: uppercase;

    &:hover {
      outline-color: rgb(1, 15, 42);
      background-color: rgba(14, 93, 178, 0.5);
    }
  }

  .app-header-menu-items li a {
    text-decoration: none;
    color: inherit;
  }

  .app-header-menu-container {
    background-color: rgba(1, 15, 42, 0.75);
    padding: 38px 0;
    width: 297px;
    position: relative;
  }

  .app-header-menu-container h1 {
    text-transform: uppercase;
    font-size: 1.7em;
    font-weight: 300;
    line-height: 1.4em;
    text-align: left;
    margin: 0 0 0.6em;
    letter-spacing: 0.1em;
  }

  .app-header-map-container {
    width: 560px;
    height: 400px;
    overflow: hidden;
  }

  .app-header-scores-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 1 auto;
    flex-wrap: wrap;
    margin: 0 50px;
  }

  .app-header-score-container {
    background: rgba(1, 15, 42, 0.75);
    padding: 0;
    margin: 1em auto;
  }

  .app-header-booking-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #B07F2E;
    font-size: 14px;
    img {
      width: 14.625em;
    }
  }

  .app-header-booking-block {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    background: #fff;
    padding: 0.625em;
    width: 13.375em;
  }

  .app-header-booking-logo {
    background: #003580;
    width: 14.625em;
    padding: 0.3125em 0 0;
  }

  .app-header-booking-logo img {
    height: 1.5625em;
  }

  .app-header-booking-score__badge {
    background: #003580;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: 500;
    justify-content: center;
    vertical-align: baseline;
    border-radius: 0.375em 0.375em 0.375em 0;
    height: 2em;
    width: 2em;
  }

  .app-header-booking-score__content {
    margin-left: 0;
    margin-right: 0.5em;
    text-align: right;
  }

  .app-header-booking-score__title {
    color: #003580;
    font-size: 1em;
    font-weight: 500;
    line-height: 1;
  }

  .app-header-booking-score__text {
    color: #707070;
    font-size: 0.75em;
    font-weight: 500;
    line-height: 1;
    margin-top: 0.25em;
  }

  .app-header-tripadvisor-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #B07F2E;
    font-size: 14px;
    height: 5.4375em;
    overflow: hidden;
    img {
      width: 14.625em;
    }
  }

  .app-header-tripadvisor-logo {
    background: #00a680;
    width: 14.625em;
    padding: 0;
  }

  .app-header-form {
    flex: 0 0 auto;
    width: 100%;
    background: rgba(1, 15, 42, 0.85);
  }

  .app-header-form form {
    margin: 1em;
  }

  .app-header-lang-wrap {
    z-index: 1;
    border-radius: 3px;
    overflow: hidden;
    background-color: rgba(42, 70, 125, 1.7);
    position: absolute;
    padding: 8px;
    margin-top: 3px;
    text-align: right;
    right: 0;
  }

  .app-header-language {
    cursor: pointer;
    line-height: 2em;

    img {
      height: 12px;
      margin-bottom: -3px;
    }
  }

  .app-header-auth {
    position: absolute;
    right: 50px;
    background: rgba(236, 235, 235, 1);
    padding: 17px 22px;
    box-shadow: rgb(204, 204, 204) 1px 3px 8px;
    color: #021843;
    z-index: 1;
  }

  .top {
    position: fixed;
    bottom: 27px;
    font-size: 48px;
    font-weight: 100;
    color: #B07F2E;
    cursor: pointer;
    z-index: 100;
  }

  &__back {
    display: none;
    position: fixed;
    top: 5px;
    left: 10px;
    font-size: 48px;
    font-weight: 100;
    color: #B07F2E;
    cursor: pointer;
    z-index: 100;
  }

  &__callback {
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-size: 0.7em;
    line-height: 1.2em;
    text-shadow: rgb(35, 38, 53) 1px 1px 1px;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 640px) {
  .app-header {
    .app-logo {
      display: none;
    }
    .app-header-title {
      width: 640px;
      margin: 18px 0 6px;
    }
    .app-header-right {
      margin: 6px 24px;

      .app-header-login,
      .app-header-lang {
        padding: 0;
        margin: 6px 0;
      }
      .app-header-login {
        max-width: 235px;
      }
    }
    .app-header-menu {
      padding: 0;
      flex-direction: column;
      align-items: stretch;
    }
    .app-header-menu-container {
      width: 100%;
      padding: 0 0 15px;
    }
    .app-header-login-wrapper {
      align-items: center;
    }
    .app-header-login-container {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .app-header-scores-container {
      flex-direction: row;
      margin: 0;
      flex-grow: 1;
      align-items: flex-end;
      .app-header-booking-container,
      .app-header-tripadvisor-container {
        font-size: 10px;
      }
    }
    .app-header-score-container {
      margin: 5px auto;
    }
    .app-header__toggle {
      display: flex;
    }
    &__back {
       display: block;
    }
  }
}

