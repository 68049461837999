@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.app-slider {
  position: relative;
  margin: 0 0;

  &__left,
  &__right {
    position: absolute;
    top: 50%;
    font-size: 3em;
    font-weight: 100;
    color: rgba(25, 25, 25, 0.4);
    margin: -0.5em 0 0;
    padding: 0;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    @media only screen and (max-width: 640px) {
      opacity: 1;
    }
  }

  &__left {
    left: 12px;
  }

  &__right {
    right: 12px;
  }

  &:hover &__left,
  &:hover &__right {
    opacity: 1;
    transition: opacity 0.5s;
  }
  .slick-slider {

    .slick-list, .slick-track {
      width: 100%;
    }
  }
}

.app-content-slider {
  &__block {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    background: #fff;
    overflow: hidden;
  }

  &__block-text-wrapper {
    flex: 1 0 50%;
  }

  &__block-text {
    text-align: left;
    margin: 60px 70px 30px;
  }

  &__block-text h3 {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 400;
    font-size: 1.2em;
    a {
      text-decoration: none;
      color: inherit;
    }
  }

  &__block-img {
    flex: 1 0 50%;
    width: 50%;
    display: flex;
    align-items: center;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .slick-slider {
    overflow: hidden;
  }

  @media only screen and (max-width: 1040px) {
    &__block {
      flex-wrap: wrap;
    }
    &__block-img {
      width: 100%;
      flex-basis: 100%;
      max-height: 200px;
    }
    &__block-text {
      text-align: left;
      margin: 15px 35px 2px;
    }
  }
}
