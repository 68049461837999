.photo-gallery {
  .categories-wrapper {
    padding: 0;
    background-color: white;

    .names-slider {
      padding-top: 20px;
    }

    .photo-gallery__thumbs {
      bottom: 0;
      display: flex !important;
      justify-content: center;
      background: rgba(1, 15, 42, 0.75);
      li {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        overflow: hidden;

        img {
          width: 100%;
          opacity: 0.4;
        }
        &.slick-active {
          img {
            opacity: 1;
          }
        }
      }
    }
  }
}



