.app-modal__container {
    width: 100%;
    height: 100%;
    overflow: scroll;
    margin: 0 auto;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100001;
    background-color: rgba(1, 15, 42, 0.9);
    color: #000000;
}