.app-footer {
  flex: 0 0 auto;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background: #010F2A;
  color: #B07F2E;
  text-align: left;
  padding: 0 35px;
}

.app-footer__phone {
  font-size: 22px;
  font-weight: 100;
  line-height: 31px;
  text-decoration: none;
  color: #B07F2E;
  display: block;
  white-space: nowrap;
}

.app-footer__email {
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  text-decoration: none;
  color: #B07F2E;
  display: block;
  white-space: nowrap;
}

.app-footer__social {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  span, a {
    display: block;
    font-size: 30px;
    margin: 10px 10px 10px 0;
    color: #B07F2E;
  }
}

.app-footer-menu {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.app-footer-menu h3 {
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.app-footer-menu-items {
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0;
}

.app-footer-menu-items li {
  font-size: 10px;
  line-height: 23px;
  color: #B07F2E;
}

.app-footer-menu-items li a {
  text-decoration: none;
  font-size: 11px;
  line-height: 23px;
  color: #B07F2E;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  border-bottom: 1px solid transparent;
  margin-left: 5px;

  &:hover {
    border-color: #B07F2E;
  }
}

.app-footer-menu-container {
  margin: 25px;
  padding: 20px;
}

.app-footer__address {
  flex: 1 0 100%;
  padding-bottom: 100px;
  margin-top: 20px;
  text-align: center;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 15px;
}

@media only screen and (max-width: 1040px) {
  footer {
    display: block !important;

    .app-footer-menu-container {
      margin: 0 auto;
      width: 50%;
    }
  }
}